import * as React from 'react';
import { Modal } from "react-bootstrap";
const { Component } = require("react");

class RedirectModal extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
     if (this.props.show) {
        setTimeout(() => {
            window.location = `https://www.ebay.com/sch/thrifty_daze/m.html`;
          }, 1500);
     }
    }
   }
  render() {
    return (
      <Modal show={this.props.show}>
        <Modal.Body className={`bg-${this.props.theme} border-${this.props.theme === 'light'? 'dark' : 'light'}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="container text-center">
                <h4>Redirecting to eBay Store</h4>
                <div className="spinner-border">
                  <i className="fa fa-hand-peace-o"></i>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
};

export default RedirectModal;
