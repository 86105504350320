import React from "react"
import { Helmet } from "react-helmet"
import "../styles/scss/custom.scss"
import "font-awesome/css/font-awesome.css"
import ThemeSwitch from '../components/theme-switch';
import Footer from "../components/footer"
import Banner from "../components/banner"
import EbayButton from "../components/ebay-button"
import BlogButton from "../components/blog-button"

const Home = () => {

    return(
    <>
      <Helmet>
        <title>Thrifty Daze - Cool Thrifts, All Daze</title>
      </Helmet>
      
      <div className="container-fluid">
        <div className="row fixed-top d-flex flex-row-reverse mr-2">
        <ThemeSwitch />
        </div>
        <div className="row">
          <Banner></Banner>
          </div>
        <div className="row">
          <EbayButton></EbayButton>
        </div>
        <div className="row">
          <BlogButton></BlogButton>
        </div>
        <Footer></Footer>
      </div>
    </>
  )
}

export default Home;