import * as React from "react"
import { ThemeContext } from './theme-context'
import RedirectModal from "./redirect-modal"

const EbayButton = () => {
  const theme = React.useContext(ThemeContext).theme;
  const [showModal, toggleModal] = React.useState(false);

    return (
      <>
        <RedirectModal show={showModal} theme={theme} />
        <button
          className={`btn btn-lg btn-block 
          ${theme === 'dark' ? 'btn-outline-light' : 'btn-outline-dark'}`}
          onClick={toggleModal}
        >
          <i className="fa fa-shopping-cart"></i> <span>Shop</span>
        </button>
      </>
    )
  }

export default EbayButton
