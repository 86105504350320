import * as React from 'react';
import { ThemeContext } from './theme-context'
import logo from "../../static/logo_light.png"
import logoDark from "../../static/logo_dark.png"

const Banner = () => {
  const theme = React.useContext(ThemeContext).theme;

    return (
        <img
        src={theme === 'dark' ? logoDark : logo}
        alt="Thrifty Daze - Cool Thrifts, All Daze"
        className="img-fluid mx-auto d-block"
      />
);
};

export default Banner;