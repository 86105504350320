import * as React from "react"
import { Link } from "gatsby"
import { ThemeContext } from './theme-context'

const BlogButton = () => {
  const theme = React.useContext(ThemeContext).theme;

    return (
      <Link to="/blog/" 
      className={`btn btn-lg btn-block 
          ${theme === 'dark' ? 'btn-outline-light' : 'btn-outline-dark'}`}
      >
          <i className="fa fa-book"></i> <span>Blog</span>
        </Link>
    )
}

export default BlogButton
